import request from '@/utils/request'

// 文章分类
export function newtype(data) {
  return request({
    url: '/v2/wsyg/articleCat',
    method: 'POST',
    data: data
  })
}
// 文章列表
export function newslist(data) {
  return request({
    url: '/v2/wsyg/articleList',
    method: 'POST',
    data: data
  })
}
// 文章详情 缓存代替
export function articleApi(data) {
  return request({
    url: '/v2/wsyg/article',
    method: 'POST',
    data: data
  })
}
// 广告位置
export function swiperapi(data) {
  return request({
    url: '/v2/wsyg/adPosition',
    method: 'POST',
    data: data
  })
}

