<template>
<div class="contentindex servewrap divMa">

  <titleDiv :title="catName" english-title="NEW INFORMATION"></titleDiv>

  <div class="contentindex wow animate__animated animate__fadeInRight">
    <div class="contentitem" v-for="(item, index) in news" :key="index">

      <div class="contentitem_img">
        <img class="newimg" :src="staticurlSrc + item.image1" />
      </div>

      <div class="contentitem_info" @click="push(item)">
        <div class="contentitem_model"></div>
        <div class="contentitem_title"><span v-html="item.articleName"></span></div>
        <div class="contentitem_text">{{ item.articleDesc }}</div>
        <div class="timeitem">{{ item.newtime }}</div>
      </div>

    </div>
  </div>
  <div class="pagebox">
    <el-pagination
        layout="prev, pager, next"
        :total="pageconfig.total"
        :page-size="pageconfig.page_size"
        :current-page="pageconfig.current_page"
        @current-change="pagechange"
    >
    </el-pagination>
  </div>
</div>
</template>

<script>
import '@/style/css/index.css';
import {newslist, newtype} from "@/api/news";
import {staticurl} from "@/utils/baseurl";
import Swiper from "swiper";
import wow from "wowjs";
import sidebar from "@/components/sidebar";
import titleDiv from "@/components/title.vue";
import alert from "element-ui/packages/alert";
export default {
  components: {
    sidebar,
    titleDiv
  },
  name: "informationNews",
  data(){
    return{
      project:this.$store.state.project,
      // projectid:this.$store.state.project.projectid,
      staticurlSrc:staticurl,
      tabbar_active: 0,
      catName:'',
      news: [],
      pageconfig: {
        total: 0,
        page_size: 4,
        current_page: 1,
      },
    }
  },
  props:["titleEnglish","bjImgHidden"],
  methods: {
    yqtabbar(index) {
      this.tabbar_active = index;
    },

    async getdata(current, cSize) {
      let data;
      let number = 1;
      let totalNum = 1;
      if (this.project.servedata) {

        let newstypes = (
            await newtype({ apartmentSid: this.project.projectid })
        ).data.result;//文章分类

        if(newstypes!=''&&newstypes!=null){
          this.catName = newstypes[0].catName
        }
        data = [];
        for (let index = 0; index < newstypes.length; index++) {
          let newstype = newstypes[index].articleCatSid;//文章分类Sid

          let res = await newslist({
            apartmentSid: this.project.projectid,
            articleCatSid: newstype,
            pageSize: cSize,
            pageNum: current,
          });//分类-->文章列表

          totalNum = res.data.number;//文章总条数
          let cNumber = Math.ceil(res.data.number / cSize);//总条数/条数===页码
          cNumber > 0 ? (number = cNumber) : (number = 1);//三元：页码>0 ？ 页码 ：1

          data = data.concat(
              data,
              res.data.result.map((item, index) => {
                item.newtime =
                    new Date(item.craetdOn).getFullYear() +
                    "/" +
                    (new Date(item.craetdOn).getMonth() + 1) +
                    "/" +
                    new Date(item.craetdOn).getDate();
                return item;
              })
          );//数据处理

        }
      } else {
        data = [];
      }
      let currentdata = data;//for处理完的数据
      return {
        // total: data.length,
        total: totalNum,
        size: this.pageconfig.page_size,
        current,
        data: currentdata,
        number: number,
      };
    },
    async pagechange(page) {
      let data = await this.getdata(page, this.pageconfig.page_size);//获取数据
      this.pageconfig.total = data.total; // data.total;
      this.pageconfig.current_page = page;
      this.pageconfig.page_size = data.size;
      this.news = data.data;
    },
    push(item){
      console.log(item)
      let key="informationNewsDetails"+item.articleSid
      window.sessionStorage.setItem(key, JSON.stringify(item))
      this.$router.push({
        path: '/informationNewsDetails?articleSid='+item.articleSid
      })
    },
  },
  beforeMount() {
  },
  mounted() {
    this.pagechange(1);//初始化加载数据
    this.$nextTick(() => {
      //new wow.WOW().init();
    });
    // 页面增加全局点击
    window.addEventListener("click", this.hideChat);
  },
  beforeDestroy() {
    /* 组件销毁的时候要移除侦听器 */
    window.removeEventListener("click", this.hideChat);
  },
}
</script>

<style scoped>

.servewrap {
  font-size: 12px;
}
/* 第一块内容 */
.contentindex {
  /*width: 100%;*/
  box-sizing: border-box;
  /* padding-top: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin: 60px 0;*/
}

.contentitem {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  /* overflow: hidden; */
  margin: 20px 0;
}

.contentitem_time {
  min-width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.time {
  line-height: 30px;
  text-align: center;
}

.day {
  font-size: 22px;
}

.contentitem_img {
  /* min-width: 460px;
      height: 100%;
      overflow: hidden; */
  font-size: 0;
}

.newimg {
  width: 460px;
  height: 200px;
  border-radius: 10px;
}

.contentitem_info {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  box-shadow: 2px 2px 2px 0px rgba(222, 222, 222, 0.4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.contentitem_model {
  width: 0px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.06);
  transition: all 0.6s;
  border-radius: 6px;
}

.contentitem_info:hover .contentitem_model {
  width: 100%;
}

.contentitem_title {
  width: 100%;
  text-overflow: ellipsis;
  line-height: 30px;
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: 800;
}

.contentitem_text {
  text-overflow: ellipsis;
  display: box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 22px;
  letter-spacing: 2px;
}

.timeitem {
  color: #aaaaaa;
  letter-spacing: 2px;
}

.pagebox {
  display: flex;
  justify-content: center;
  margin: 20px;
}

>>> .el-pager .number {
  font-size: 16px;
}

>>> .el-pager .number.active {
  color: purple;
}
@media (max-width: 600px){
  .contentitem {
    flex-direction: column;
  }
  .newimg {
    width: 100%;
  }
  .contentitem_info{
    padding: 0px;
  }
}
</style>
