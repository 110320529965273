<!--园区动态 新闻 最新资讯-->
<template>
  <div class="servewrap">
    <headbox></headbox>

    <!--banner组件-->
    <banner positionSid="3"></banner>

    <!--热点资讯-->
    <informationNews></informationNews>

    <!--侧边导航-->
    <sidebar ref="mysiderbar"></sidebar>

    <!--底部栏-->
    <!--<foot></foot>-->

    <!--底部信息-->
    <bottom></bottom>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import headbox from "@/components/headfloat";
import bottom from '@/components/bottom';
import sidebar from "@/components/sidebar";
import wow from "wowjs";
import banner from "@/components/banner";
import informationNews from "@/components/informationNews.vue";

export default {
  components: {
    informationNews,
    banner,
    headbox,
    bottom,
    sidebar,
  },
  data() {
    return {
      mySwiper: {},
      tabbar_active: 0,
    };
  },
  methods: {
    hideChat(e) {
      this.$refs.mysiderbar.hideChat();
    },
    yqtabbar(index) {
      this.tabbar_active = index;
    },
  },
  beforeMount() {
  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      // initialSlide: 0,
      // direction: 'vertical',
      // effect : 'coverflow',
      mousewheelControl: true,
      allowSlidePrev: false,
      allowSlideNext: false,
      autoplay: {
        delay: 2000,
      },
      on: {
        init: function (swiper) {
          //     console.log(swiper)
          //     let slide = swiper.slides[0].classList;
          //     console.log(slide)
          // slide.add('ani-slide');
        },
        transitionStart: function (swiper) {
          // for(let i = 0; i < swiper.slides.length; i++){
          //   	let slide = swiper.slides[i].classList;
          //     slide.remove('ani-slide');
          // }
        },
        transitionEnd: function (swiper) {
          //     console.log(swiper)
          // let slide = swiper.slides[swiper.activeIndex].classList;
          // slide.add('ani-slide');
        },
      },
    });
    this.$nextTick(() => {
      new wow.WOW().init();
    });
    // 页面增加全局点击
    window.addEventListener("click", this.hideChat);
  },
  beforeDestroy() {
    /* 组件销毁的时候要移除侦听器 */
    window.removeEventListener("click", this.hideChat);
  },
};
</script>

<style scoped>
.servewrap {
  font-size: 12px;
}
</style>
